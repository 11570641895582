@use '@scss/common' as *;

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@mixin transparentBorders {
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.loader {
  border: 2px solid;
  border-radius: 50%;
  position: relative;
  animation: loader 1s linear infinite;
  height: 16px;
  width: 16px;
}

.size--sm {
  height: base(1.6);
  width: base(1.6);
}

.size--md {
  height: base(2.2);
  width: base(2.2);
}

.size--lg {
  height: base(2.8);
  width: base(2.8);
}

.size--xl {
  height: base(4);
  width: base(4);
}

.color--light {
  border-color: var(--color-grey);
  @include transparentBorders;
}

.color--dark {
  border-color: var(--color-text-grey);
  @include transparentBorders;
}

.color--primary {
  border-color: var(--color-primary);
  @include transparentBorders;
}
