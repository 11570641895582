@use './vars.scss'as *;
@use './typography.scss'as *;
@use './mediaQueries.scss'as *;
@use './formFields.scss'as *;
@use './functions.scss'as *;

@font-face {
  font-family: "SF Pro Display Light";
  src: local("SF Pro Display Light"), local("SFProDisplay-Light"),
    url("../assets/fonts/SFProDisplay-Light.woff") format("woff"),
    url("../assets/fonts/SFProDisplay-Light.otf") format("otf"),
    url("../assets/fonts/SFProDisplay-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Regular";
  src: local("SF Pro Display Regular"), local("SFProDisplay-Regular"),
    url("../assets/fonts/SFProDisplay-Regular.woff") format("woff"),
    url("../assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Regular.otf") format("otf"),
    url("../assets/fonts/SFProDisplay-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Text Regular";
  src: local("SF Pro Text Regular"), local("SFProText-Regular"),
    url("../assets/fonts/SFProText-Regular.woff") format("woff"),
    url("../assets/fonts/SFProText-Regular.otf") format("otf"),
    url("../assets/fonts/SFProText-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Text Semibold";
  src: local("SF Pro Text Semibold"), local("SFProText-Semibold"),
    url("../assets/fonts/SFProText-Semibold.woff") format("woff"),
    url("../assets/fonts/SFProText-Semibold.otf") format("otf"),
    url("../assets/fonts/SFProText-Semibold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// This code is generated by `yarn styles:updateCSSVars`
:root {
  --border-rad-button: #{$border-rad-button};
  --border-rad-container: #{$border-rad-container};
  --border-rad-input: #{$border-rad-input};
  --border-rad-lg: #{$border-rad-lg};
  --border-rad-md: #{$border-rad-md};
  --border-rad-modal: #{$border-rad-modal};
  --border-rad-sm: #{$border-rad-sm};
  --border-rad-xs: #{$border-rad-xs};
  --box-shadow-grey: #{$box-shadow-grey};
  --breakpoint-l-width: #{$breakpoint-l-width};
  --breakpoint-m-width: #{$breakpoint-m-width};
  --breakpoint-s-width: #{$breakpoint-s-width};
  --breakpoint-xl-width: #{$breakpoint-xl-width};
  --breakpoint-xs-width: #{$breakpoint-xs-width};
  --color-border-grey: #{$color-border-grey};
  --color-browser-outline-color: #{$color-browser-outline-color};
  --color-danger-100: #{$color-danger-100};
  --color-danger-200: #{$color-danger-200};
  --color-danger-300: #{$color-danger-300};
  --color-danger-400: #{$color-danger-400};
  --color-danger-500: #{$color-danger-500};
  --color-danger-600: #{$color-danger-600};
  --color-danger-700: #{$color-danger-700};
  --color-danger-800: #{$color-danger-800};
  --color-danger-900: #{$color-danger-900};
  --color-dark-grey: #{$color-dark-grey};
  --color-disabled-grey: #{$color-disabled-grey};
  --color-green: #{$color-green};
  --color-grey: #{$color-grey};
  --color-info-100: #{$color-info-100};
  --color-info-200: #{$color-info-200};
  --color-info-300: #{$color-info-300};
  --color-info-400: #{$color-info-400};
  --color-info-500: #{$color-info-500};
  --color-info-600: #{$color-info-600};
  --color-info-700: #{$color-info-700};
  --color-info-800: #{$color-info-800};
  --color-info-900: #{$color-info-900};
  --color-light-black: #{$color-light-black};
  --color-light-green: #{$color-light-green};
  --color-light-grey-highlight: #{$color-light-grey-highlight};
  --color-light-red: #{$color-light-red};
  --color-main-menu-grey: #{$color-main-menu-grey};
  --color-medium-grey: #{$color-medium-grey};
  --color-off-black: #{$color-off-black};
  --color-off-white: #{$color-off-white};
  --color-primary-100: #{$color-primary-100};
  --color-primary-200: #{$color-primary-200};
  --color-primary-300: #{$color-primary-300};
  --color-primary-400: #{$color-primary-400};
  --color-primary-500: #{$color-primary-500};
  --color-primary-600: #{$color-primary-600};
  --color-primary-700: #{$color-primary-700};
  --color-primary-800: #{$color-primary-800};
  --color-primary-900: #{$color-primary-900};
  --color-primary-lightest: #{$color-primary-lightest};
  --color-primary: #{$color-primary};
  --color-red: #{$color-red};
  --color-success-100: #{$color-success-100};
  --color-success-200: #{$color-success-200};
  --color-success-300: #{$color-success-300};
  --color-success-400: #{$color-success-400};
  --color-success-500: #{$color-success-500};
  --color-success-600: #{$color-success-600};
  --color-success-700: #{$color-success-700};
  --color-success-800: #{$color-success-800};
  --color-success-900: #{$color-success-900};
  --color-text-grey-placeholder: #{$color-text-grey-placeholder};
  --color-text-grey: #{$color-text-grey};
  --color-transparent-black-15: #{$color-transparent-black-15};
  --color-warning-100: #{$color-warning-100};
  --color-warning-200: #{$color-warning-200};
  --color-warning-300: #{$color-warning-300};
  --color-warning-400: #{$color-warning-400};
  --color-warning-500: #{$color-warning-500};
  --color-warning-600: #{$color-warning-600};
  --color-warning-700: #{$color-warning-700};
  --color-warning-800: #{$color-warning-800};
  --color-warning-900: #{$color-warning-900};
  --color-white: #{$color-white};
  --color-whitish-grey: #{$color-whitish-grey};
  --color-workflow-grey: #{$color-workflow-grey};
  --default-trans-time: #{$default-trans-time};
  --focus-visible-outline: #{$focus-visible-outline};
  --font-display-light: #{$font-display-light};
  --font-display-regular: #{$font-display-regular};
  --font-regular-text: #{$font-regular-text};
  --font-semibold-text: #{$font-semibold-text};
  --grid-container-gutter-large: #{$grid-container-gutter-large};
  --grid-container-gutter-small: #{$grid-container-gutter-small};
  --grid-container-gutter: #{$grid-container-gutter};
  --grid-container-max-width: #{$grid-container-max-width};
  --shadow-slide-out-menu: #{$shadow-slide-out-menu};
  --spacing-iphone-notch: #{$spacing-iphone-notch};
  --spacing-lg: #{$spacing-lg};
  --spacing-md: #{$spacing-md};
  --spacing-sm: #{$spacing-sm};
  --spacing-xl2: #{$spacing-xl2};
  --spacing-xl3: #{$spacing-xl3};
  --spacing-xl4: #{$spacing-xl4};
  --spacing-xl: #{$spacing-xl};
  --spacing-xs: #{$spacing-xs};
  --z-index-prevent-interaction: #{$z-index-prevent-interaction};
  --z-index-slide-out-menu: #{$z-index-slide-out-menu};
  // ## INSERT NEW VARS HERE ##

  // Code generation stops here
  @include mid-break {
    --grid-container-gutter: #{$grid-container-gutter-small};
  }
}

html {
  font-size: 62.5%;
  /* font-size 1rem = 10px on default browser settings */
  color: var(--color-white);
  max-width: var(--grid-container-max-width);
  margin: auto;
  -webkit-font-smoothing: antialiased;

  body {
    // override open dialog styles
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
}

body {
  background-color: var(--color-off-black);
}

body,
#root,
.App {
  height: 100%;
}

p {
  @include body;

  a {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
  }
}

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4 {
  @include heading-4;
}

small {
  @include body-small;
}

button,
a {
  @include body;
}

a {
  text-underline-offset: .5px;
}

input {
  @include form-field;
}

label {
  @include body-small;
  font-weight: 600;
  color: var(--color-off-black);
  margin-bottom: base(0.8);
  display: inline-flex;
}

li {
  @include body-small;
  color: var(--color-text-grey);
  margin-left: base(2.5);
}

button {
  @include mid-break {
    outline: none !important;
  }
}

.ptr__children {
  // This has to be here to override the plugin
  overflow: auto !important;
}