@use '@scss/common' as *;
@use 'sass:color' as sassColor;

.fullscreenLoader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: sassColor.scale($color-off-black, $alpha: -15%);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-prevent-interaction);
}
