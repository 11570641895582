@use './common' as *;

@mixin disabled {
  background-color: var(--color-disabled-grey);
  color: var(--color-text-grey-placeholder);
  border-color: transparent;
  cursor: not-allowed;
  user-select: none;
}

@mixin form-field-pseudo {
  &::-webkit-input-placeholder {
    @include body;
    color: var(--color-text-grey-placeholder);
  }
  &::-moz-placeholder {
    @include body;
    color: var(--color-text-grey-placeholder);
  }
  &:placeholder-shown {
    &:not(:focus):not(:disabled) {
      background-color: var(--color-light-grey-highlight);
    }
  }
  &:focus,
  &:active {
    border-color: var(--color-primary);
    outline: 0;
    background-color: var(--color-white);
  }
  &:disabled {
    @include disabled();
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

@mixin form-data-attrs {
  &[data-has-error="true"] {
    border-color: var(--color-red);
    &:focus-visible {
      border-color: var(--color-primary);
    }
  }
  &[data-has-no-value="true"]:not([data-has-error="true"]) {
    &:not(:focus):not(:disabled) {
      background-color: var(--color-light-grey-highlight);
    }
  }
}

@mixin form-field {
  @include body;
  @include form-field-pseudo;
  @include form-data-attrs;
  margin: 0;
  padding: base(1.2) base(1.6);
  border: 1px solid var(--color-border-grey);
  border-radius: var(--border-rad-input);
  color: var(--color-off-black);
  background-color: var(--color-white);
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  &[type="date"] {
    line-height: 1.5;
  }
  &[type="checkbox"] {
    padding: 0;
    border: none;
    height: 20px;
    width: 20px;
    min-width: 20px;
    border: 1px solid var(--color-text-grey);
    cursor: pointer;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      border-radius: inherit;
      background-color: var(--color-off-black);
      opacity: 0;
      transition: ease opacity var(--default-trans-time);
      z-index: 0;
    }

    &:before {
      color: white;
      background-color: transparent;
      background-image: url("../assets/svgs/checkmark.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: none;
      z-index: 1;
    }

    &:checked {
      border-color: var(--color-off-black);
      color: var(--color-white);

      &:before,
      &:after {
        opacity: 1;
      }
    }
  }
}

@mixin form-field-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  input,
  select,
  textarea {
    @include form-field;
  }

  textarea {
    height: unset;
    min-height: base(12.5);
    max-height: base(15);
    overflow: auto;
  }
}
