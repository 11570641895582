.environmentLabel {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0%;
  cursor: pointer;
  transition-delay: 250mx opacity;

  background-color: var(--color-off-black);
  color: var(--color-white);

  &:hover {
    opacity: 100%;
  }

  &Hidden {
    display: hidden;
  }
}

.pullToRefreshContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--spacing-xl4);
  width: 100%;

  background-color: var(--color-dark-grey);
}