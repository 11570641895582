@use './vars.scss' as *;

@mixin extra-small-break {
  @media (max-width: $breakpoint-xs-width) {
    @content;
  }
}

@mixin small-break {
  @media (max-width: $breakpoint-s-width) {
    @content;
  }
}

@mixin mid-break {
  @media (max-width: $breakpoint-m-width) {
    @content;
  }
}

@mixin large-break {
  @media (max-width: $breakpoint-l-width) {
    @content;
  }
}

@mixin extra-large-break {
  @media (max-width: $breakpoint-xl-width) {
    @content;
  }
}
