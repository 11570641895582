@use './functions.scss'as *;

// Body
@mixin shared-body {
  font-family: var(--font-display-regular);
  margin: base(0.5) 0 base(1.2);
  line-height: 160%;
}

@mixin body {
  @include shared-body;
  font-size: base(1.5);
}

@mixin body-small {
  @include shared-body;
  font-size: base(1.5);
  line-height: 140%;
}

// Heading
@mixin shared-heading {
  font-family: var(--font-display-light);
  font-weight: 300;
  margin: base(0.5) 0 base(1.2);
  line-height: 115%;
  letter-spacing: base(.02);
}

@mixin heading-1 {
  @include shared-heading;
  font-size: base(3.2);
  line-height: 140%;
}

@mixin heading-2 {
  @include shared-heading;
  font-size: base(3);
}

@mixin heading-3 {
  @include shared-heading;
  font-size: base(2.496);
}

@mixin heading-4 {
  @include shared-heading;
  font-size: base(2.08);
  line-height: 130%;
}