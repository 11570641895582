@use '@scss/common' as *;

$progressBarHeight: 3px;

.Toastify {
  .Toastify__toast-container {
    margin-top: var(--spacing-sm);
    left: var(--spacing-sm);
    right: var(--spacing-sm);
    width: auto;
    padding: 0;
    transform: none;
    max-width: calc(#{var(--grid-container-max-width)} - (#{var(--spacing-md)} * 2));
    margin-left: auto;
    margin-right: auto;
  }

  .Toastify__toast {
    padding: 0 var(--spacing-sm);
    min-height: 55px;
    margin-bottom: var(--spacing-sm);
    border: 1px solid transparent;
    color: var(--color-off-black);
    border-radius: var(--border-rad-sm);
  }

  .Toastify__toast-body {
    @include body-small;
    margin: 0;
    display: flex;
    align-items: center;
    padding: base(0.8);
  }

  .Toastify__close-button {
    align-self: center;
    color: var(--color-off-black);
    margin: 0;
    min-width: 14px;
  }

  // Progress bar
  .Toastify__progress-bar {
    height: $progressBarHeight;
  }

  // Info
  .Toastify__toast--info {
    border-color: var(--color-border-grey);
    background: var(--color-grey);
  }
  .Toastify__progress-bar--info {
    background-color: var(--color-border-grey);
  }

  // Success
  .Toastify__toast--success {
    border-color: var(--color-primary);
    background: var(--color-light-green);
  }
  .Toastify__progress-bar--success {
    background-color: var(--color-primary);
  }

  // Error
  .Toastify__toast--error {
    background-color: var(--color-light-red);
    border-color: var(--color-red);
  }
  .Toastify__progress-bar--error {
    background-color: var(--color-red);
  }
}
